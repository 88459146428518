































import Vue from "vue";
import { VerifyEmail } from "@/interfaces/auth/verify_email";
import SubmitButton from "@/components/auth/SubmitButton.vue";
import { mapActions, mapGetters } from "vuex";
import { GET_USER_DETAILS, VERIFY_EMAIL } from "@/store/modules/auth/constants";
import router from "@/router";
export default Vue.extend({
  name: "VerifyEmail",
  components: { SubmitButton },
  async created() {
    const code = this.$route.params.code;
    if (code) {
      this.verification_code = code;
      await this.submit_form();
    }
  },
  data(): VerifyEmail {
    return {
      decoration_img_src: require("@/assets/illustrations/email.svg"),
      verification_code: "",
      is_valid: false
    };
  },
  computed: {
    ...mapGetters("auth", {
      get_user_details: GET_USER_DETAILS
    })
  },
  watch: {
    verification_code(new_value: string) {
      this.is_valid = /^[0-9]{4}$/.test(new_value);
    }
  },
  methods: {
    ...mapActions("auth", {
      verify_email: VERIFY_EMAIL
    }),
    async submit_form() {
      if (this.verification_code && this.verification_code.length === 4) {
        const response = await this.verify_email(this.verification_code);
        if (response) {
          await router.push({
            name: "email-verified"
          });
          this.verification_code = "";
          this.is_valid = false;
        }
      }
    }
  }
});
